<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span class="vol-event-name">志愿组织入驻</span>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />
        <img src="../../assets/volunteer/org.png" class="vol-org" />
      </div>
      <div class="vol-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              志愿组织入驻
            </li>
          </ol>
        </nav>
        <form
          class="row g-3 needs-validation"
          @submit.prevent="submit"
          novalidate
        >
          <div class="col-md-6">
            <label class="form-label"><span>*</span>负责人真实姓名</label>
            <input
              disabled
              :value="user.real_name"
              type="text"
              class="form-control"
              placeholder="请输入真实姓名"
              required
            />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please select a valid state.</div>
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>负责人手机号码</label>
            <input
              type="number"
              :value="user.mobile"
              name="mobile"
              disabled
              class="form-control"
              placeholder="请输入手机号码（如绑定微信请输入绑定微信的手机号码）"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>负责人性别</label>
            <select name="gender" class="form-select">
              <option value="0" selected>请选择</option>
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>负责人身份</label>
            <select disabled name="position" class="form-select">
              <option selected>{{ user.position }}</option>
            </select>
          </div>
          <div class="col-md-12">
            <label class="form-label">负责人所在单位</label>
            <input
              type="text"
              :value="user.company"
              name="company"
              disabled
              class="form-control"
              placeholder="请规范填写全称"
            />
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>*</span>所属区域</label>
            <select disabled id="inputState" class="form-select">
              <option selected>{{ user.city }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select disabled id="inputState" class="form-select">
              <option selected>{{ user.district }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select disabled id="inputState" class="form-select">
              <option selected>{{ user.street }}</option>
            </select>
          </div>
          <div class="col-md-12">
            <label class="form-label"> <span>*</span>服务类别（可多选）</label>
          </div>
          <div class="col-12">
            <div
              class="form-check form-check-inline"
              v-for="(item, index) in serviceList"
              :key="item"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="'inlineCheckbox' + index"
                name="industry"
                :value="item"
              />
              <label class="form-check-label" :for="'inlineCheckbox' + index">{{
                item
              }}</label>
            </div>
          </div>
          <div class="col-md-12">
            <label class="form-label">组织名称</label>
            <input
              type="text"
              name="orga_name"
              class="form-control"
              placeholder="请输入组织名称"
            />
          </div>

          <div class="col-md-12">
            <label class="form-label">上传组织标志 (建议 400*400 正方形)</label>
            <input
              class="form-control"
              type="file"
              ref="logoInput"
              accept=".jpg,.png"
              @change="updateLogo($event)"
              id="logoFile"
            />
          </div>
          <div class="col-md-12">
            <label class="form-label"
              ><span>*</span>组织简介(简介为必填, 图片非必传,
              图片最多上传9张)</label
            >
            <textarea
              class="form-control"
              placeholder="请输入组织简介"
              name="intro"
              rows="3"
              required
            ></textarea>
          </div>
          <div class="col-md-12">
            <input
              class="form-control"
              type="file"
              ref="introInput"
              accept=".jpg,.png"
              @change="updateIntro($event)"
              id="introFile"
              multiple
            />
          </div>
          <div class="col-md-12">
            <button class="btn bottom-btn" type="submit">提交</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getBaseData,
  userDetail,
  colunteerApply,
  uploadFile,
} from "@/network/API.js";
export default {
  data() {
    return {
      user: {},
      serviceList: [],
      genderAry: ["男", "女"],
      gender: -1,
      logoFileList: [],
      introFileList: [],
      uploadFiles: [],
      thumbList: [],
    };
  },
  async created() {
    this.user = await userDetail();
    this.fetchService();
  },
  methods: {
    updateLogo(event) {
      const files = event.target.files;
      var fileTypes = [".jpg", ".png"];
      let that = this;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        // var fileend = file.name.substring(file.name.lastIndexOf("."));
        // if (file.size / 1024 > 1024 * 1) {
        //   this.$toast("文件过大,仅支持小于1M的文件");
        //   break;
        // }
        if (!fileTypes.find(fileend)) {
          this.$toast("不支持的文件类型");
          break;
        }
        this.uploadFiles.push(file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          that.thumbList.push(this.result);
        };
      }
      event.target.value = "";
    },
    updateIntro(event) {},
    async fetchService() {
      let res = await getBaseData("service_type");
      this.serviceList = res;
    },
    async submit(event) {
      const data = new FormData(event.target);
      var params = {};
      for (let [a, b] of data) {
        params[a] = b;
      }
      params.industry = data.getAll("industry").join();
      params = {
        ...params,
        real_name: this.user.real_name,
        mobile: this.user.mobile,
        position: this.user.position,
        company: this.user.company,
        city: this.user.city,
        city_code: this.user.city_code,
        district: this.user.district,
        district_code: this.user.district_code,
        street: this.user.street,
        street_code: this.user.region,
        province: "河北省",
        province_code: "130000",
        type: "orga",
      };
      if (params.gender == "0") {
        this.$toast("请选择性别");
        return;
      }
      if (params.industry == "") {
        this.$toast("请选择服务类别");
        return;
      }
      if (params.orga_name == "") {
        this.$toast("请输入组织名称");
      }
      if (params.intro == "") {
        this.$toast("请输入组织名称");
      }
      console.log(params);
      // await colunteerApply({
      //   ...params,
      // });
      // this.$toast("信息已提交,请等待审核");
      // setTimeout(() => {
      //   this.$router.go(-1);
      // }, 1000);
    },
  },
};
</script>
<style>
.vol-org {
  position: absolute;
  z-index: 3;
  right: -10px;
  top: -18px;
  width: 326px;
  height: 295px;
}

.bottom-btn {
  width: 260px;
  margin: 30px auto;
  position: relative;
  z-index: 4;
  background: linear-gradient(97deg, #ffb990 0%, #ff3241 100%);
  border: none;
  color: #fff !important;
}
.form-label span {
  color: red;
}
</style>
